
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getNextWebServerSidePropsWrapper, NextWebServerSideProps, Route } from '@web/routing';
import { CategoryNews, Props } from '@web/templates/CategoryNews';
import { preparePodcastProps } from './podcast';
// FIXME: PB-6225 - Remove this page and fetch podcasts from 'category-news/podcast.tsx'
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(preparePodcastProps, Route.BrightPodcast);
export const Home = CategoryNews;
export default Home;

    async function __Next_Translate__getServerSideProps__1930710f45e__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/category-news/bright-podcast',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1930710f45e__ as getServerSideProps }
  